<template>
  <div class="wrapper">
    <div class="page">
      <div id="loader"></div>
      <div class="page-inner" id="main_page">
                
        <div class="va-card">
          <div class="row">
            <div class="col-md-12">
              <div class="dash_card_body">
                <div class="header_section">
                  <div class="back-btn mt-0 mr-2">
                    <router-link :to="{ name: 'navmenus' }"><i class="fa fa-arrow-left" aria-hidden="true"></i></router-link>
                  </div> 
                  <div class="w-100 t-wrap">
                    <h4 class="menu_header"> <span v-if="menuName !=''">({{ menuName }})</span> Menu Items</h4>
                  </div>
                  
                  <div class="">
                    <router-link :to="{ name: 'new_menu_items', params: { id: id } }">
                      <button class="btn btn-default custom_btn custon_orange">
                        <i class="fa fa-plus"></i> New sub Menu
                      </button>
                    </router-link>
                  </div>
    
                </div>
                <div class="search_section">
                  <div class="row">
                    <div class="col-md-6 col-sm-12">
                      <div class="form-group has-search mt-1">
                        <span class="fa fa-search form-control-feedback"></span>
                        <input type="text" v-model="search" @keyup="searchMenuItem()" aria-required="false" autocomplete="on" inputmode="text" name="search_input" placeholder="Search..."   class="form-control" >
                      </div>
                    </div>
    
                    <div class="col-md-2 col-sm-12">
                      <div class="form-group has-search">
                        <button class="btn btn-orange search mt-1">Search</button>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-3">
                      <select class="form-control mt-1" @change="searchMenuItem()" v-model="storeStatus">
                        <option value="">--Select status --</option>
                        <option value="1">Published</option>
                        <option value="2">Unpublished</option>
                        <option value="4">Trashed</option>
                        <option value="5">All</option>
                      </select>
                    </div>
                    
                  </div>
                </div>
              </div>
              <hr>
            </div>
    
              
              
                
                
               
            <div class="col-lg-12">
              <div class="filters" id="filter_bar" style="display:none">
                <div v-if="user.role.id == 1 || user.role.id == 2">
    
                  <span class="btn-filter mt-1">
                    <input type="checkbox" id="uncheck" @click="checkItems('uncheck')" indeterminate = true>
                  </span>
    
                  <button class="btn-filter mr-1 mt-1">
                    {{menuSelected >1 ? menuSelected + ' Menu items ': menuSelected + ' Menu item'}}  selected
                  </button>
    
                  <button class="btn-filter mr-1 mt-1" @click="bulkStatusUpdate(1)">
                    <i class='fa fa-check' style="color:green;"></i>
                    <span v-if="methodSpinner =='publish'"><span v-html="spinner"></span> </span> <span v-else> publish</span> </button>
                  <button class="btn-filter mr-1 mt-1" @click="bulkStatusUpdate(2)">
                    <i class="fa fa-close" style="color:red;" aria-hidden="true"></i>
                    <span v-if="methodSpinner =='unpublish'"><span v-html="spinner"></span></span> <span v-else> Unpublish</span> </button>
                  <button class="btn-filter mr-1 mt-1" @click="bulkStatusUpdate(4)">
                    <i class="fa fa-trash" aria-hidden="true"></i>
                    <span v-if="methodSpinner == 'trash'">
                      <span v-html="spinner"></span>
                    </span>
                    <span v-else> Trash</span>
                  </button>
                </div>
              </div>
              <div class="dash_card_body pt-0">
                <div class="table_section storeList">
                  <table class="table  table-hover">
                    <thead>
                      <tr>
                        <th width="5px">
                          <input type="checkbox" id="check_all" @click="checkItems('check')">
                        </th>
                        <th scope="col">Icons</th>
                        <th scope="col">Name</th>
                        <th scope="col">Display Name</th>
                        <th scope="col">Child Menus</th>
                       
                        <th scope="col">Status</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody v-if="!loading">
                      <tr v-for="menu in menus" :key="menu.name" draggable="true"  @drop="onDrop($event,menu)" @dragenter.prevent @dragover.prevent @dragstart="startDrag($event,menu)">
                        <td><input type="checkbox" :id="'menu_'+menu.uid" @click="selectMenuItems(menu.uid)"></td>
                        <td>
                          <span v-html="menu.icon"></span>
                        </td>
                        <td>
                          <router-link :to="{ name: 'update_menu', params: { id:menu.uid }}">
                            {{menu.name}}
                          </router-link>
                        </td>
                        <td>
                          <p>{{ menu.display_name }}</p>
                        </td>
                        <td>
                          <div v-if="menu.sub_menu.length !=0">
                            <span v-for="(childm,index) in menu.sub_menu" :key="childm.name"> 
                              {{ childm.name }} <span v-if="index + 1 < menu.sub_menu.length">, </span>
                            </span> 
                          </div>
                          <div v-else>N/A</div>
                        </td>
                       
                        <td>
                          <div class = "btn-group">
                            <button class="btn custom_btn btn-sm dropdown-toggle'" type="button"  >
                              <span v-if="menu.status == 1">
                                <i class='fa fa-check' style="color:green;"></i> 
                              </span>
                              <span v-else-if="menu.status == 2">
                                <i class="fa fa-close" style="color:red;" aria-hidden="true"></i>
                              </span>
                              <span v-else-if="menu.status == 3">
                                <i class="fa fa-archive" aria-hidden="true"></i>
                              </span>
                              <span v-else>
                                <i class="fa fa-trash"  aria-hidden="true"></i>
                              </span>
                            </button>
                            <button v-if="user.role.id == 1 || user.role.id == 2" type = "button" class = "btn btn-sm custom_btn btn-default dropdown-toggle" data-toggle = "dropdown" :id="'dropdownMenuButton'+menu.name" >
                              <span class = "caret"></span>
                              <span class = "sr-only">Toggle Dropdown</span>
                            </button>
                            <div class="dropdown-menu" :aria-labelledby="'dropdownMenuButton'+menu.name">
                              <a class="dropdown-item" @click="updateStatusPerItem(menu.uid,1)" href="#">Publish</a>
                              <a class="dropdown-item" @click="updateStatusPerItem(menu.uid,2)" href="#">Unpublish</a>
                              <a class="dropdown-item" @click="updateStatusPerItem(menu.uid,4)" href="#">Trash</a>
                            </div>
                          </div>
                              
    
                        </td>
                        <td></td>
                      </tr>
    
                    </tbody>
                    <tfoot v-if="!loading">
                      <tr>
                        <td colspan="8">
                          <div id="pagination" v-if="totalPages > 1">
                            <ul class="showItems">
                              <li>Show Items:
                                <select @change="onChangeRecordsPerPage" v-model="recordsPerPage">
                                  <option :value="10">10</option>
                                  <option :value="20">20</option>
                                  <option :value="30">30</option>
                                  <option :value="40">40</option>
                                  <option :value="50">50</option>
                                </select>
                              </li>
                                
                            </ul>
                            <Pagination :total-pages="totalPages" :per-page="recordsPerPage" :current-page="page" @pagechanged="onPageChange" />
    
                          </div>
                              
                        </td>
                      </tr>
                    </tfoot>
                  </table>
    
                </div>
                <div v-if="loading" class="loading-cover">
                  <div class="loader"> </div>
                </div>
    
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    
    <script>
    import ReusableFunctionMixins from '@/mixins/ReusableFunctionMixins'
    import ReusableDataMixins from '@/mixins/ReusableDataMixins';
    import Pagination from '@/pages/pagination/pagination.vue';
    // import { useToast } from "vue-toastification";
    // const toast = useToast();
    export default {
        components: {
            Pagination,
        },
        data() {
            return {
                menuName:'',
                menus: [],
                loading: false,
                page: 1,
                totalPages: 0,
                totalRecords: 0,
                recordsPerPage: 10,
                debounce: null,
                spinner: false,
                search:'',
                menuSelected: 0,
                status:'',
                menuIds:[],
                errorMsg: [],
                menuType: '',
                menustatus: 1,
                id: this.$route.params.id,
                description:'',
                menuOptions:[
                  {
                      "key":"super-admin",
                      "value":"Super Admin"
                  },
                  {
                      "key":"admin",
                      "value":"Admin"
                  },
                  {
                      "key":"store-manager",
                      "value":"Store Manager"
                  },
                  {
                      "key":"call-center",
                      "value":"Call center"
                  },
                  
                ]
            }
        },
        created: async function () {
            this.getMenuItems();
        },
        methods: {
          startDrag(event, item) {     
          event.dataTransfer.dropEffect = 'move'      
          event.dataTransfer.effectAllowed = 'move' 
          event.dataTransfer.setData('from_pos',item.position);
          event.dataTransfer.setData('from_id',item.uid);
        },
        async onDrop(event,item){
          const frompos = event.dataTransfer.getData('from_pos')
          const fromMenuId = event.dataTransfer.getData('from_id')
          if(this.categorySearch !=''){
            try{
              const config = {
                  headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
              };
              const url = `${this.dashBaseUrl}/update-menu-postion`;
              const payload = {
                'from': fromMenuId,// parseInt(frompos),
                'postion_from': parseInt(frompos),
                'to': item.uid,
                'postion_to':item.position
              }
              const response = await this.axios.post(url,payload, config);
              if(response.status ==200){
                this.getMenuItems();
              }

            }catch(error){
              console.log('error')
            }
          }
        },
          checkItems(type){
              document.querySelector('#uncheck').indeterminate = true;
              if(type === 'check'){
                if(document.getElementById('check_all').checked){
                   document.getElementById('filter_bar').style.display='block'
                   this.menuSelected = 0;
                    for(const menu of Object.values(this.menus)){
                      this.menuSelected +=1;
                      document.getElementById('menu_' + menu.uid).checked = true
                      if (this.menuIds.includes(menu.uid) === false) {
                            this.menuIds.push(menu.uid);
                      }
                    }
                }else{
                  document.getElementById('filter_bar').style.display='none'
                  for(const menu of Object.values(this.menus)){
                      document.getElementById('menu_' + menu.uid).checked = false
                    }
                    this.menuIds = []
                }
                
              }else{
                document.getElementById('filter_bar').style.display='none'
                document.getElementById('check_all').checked =  false
                for(const menu of Object.values(this.menus)){
                  document.getElementById('menu_' + menu.uid).checked = false
                }
                this.menuIds = []
              }
    
          },
          searchMenuItem() {
                clearTimeout(this.debounce);
                this.debounce = setTimeout(() => {
                    this.getMenuItems();
                }, 1000)
            },
          selectMenuItems(menuId){
            if(document.getElementById('menu_'+menuId).checked){
              if(this.menuIds.includes(menuId) === false){
                this.menuIds.push(menuId);
              } 
              document.getElementById('filter_bar').style.display='block'
            } else {
              if(this.menuIds.indexOf(menuId) !== -1){
                  this.menuIds = this.menuIds.filter((x) => x !== menuId);
                }
                    // checking if there is any checked box
                    for(const menu of Object.values(this.menus)){
                      if(menu.name != menuId && document.getElementById('menu_' + menu.uid).checked){
                        document.getElementById('filter_bar').style.display='block'
                        break; 
                      }else{
                        document.getElementById('filter_bar').style.display='none'
                      }
                    }
                }
                // increasing count on item selected
                this.menuSelected = 0;
                for(const menu of Object.values(this.menus)){
                  if(document.getElementById('menu_' + menu.uid).checked){
                    this.menuSelected +=1;
                  }
                }
          },
          bulkStatusUpdate(status){
              this.updateMenuItemStatus(status,this.menuIds);
          },
          updateStatusPerItem(menuId,status){
            const storeIdsArr = [];
            if(storeIdsArr.includes(menuId) === false){
              storeIdsArr.push(menuId)
            }
            this.updateMenuItemStatus(status,storeIdsArr)
          },
        
            async getMenuItems() {  
              let url = `${this.dashBaseUrl}/menu-items/${this.id}/${this.merchantID}`;
              url  = new URL(url);
              url.searchParams.set('page',this.page);
              url.searchParams.set('size',this.recordsPerPage);
              if(this.status !==''){
                url.searchParams.set('status',this.status);
              }
              if(this.search !==''){
                url.searchParams.set('search',this.search);
              }  
                this.loading = true;
                const config = {
                            headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
                      };
                this.axios.get(url,config).then((response) => {
                    if (response.status === 200) {
                        this.loading = false;
                        this.menus = response.data.data.data;
                        for(let x= 0; x < this.menus.length;x++){
                           this.menuName = this.menus[x].menu_type
                        }
                        this.totalPages = Math.ceil(response.data.data.total / this.recordsPerPage)
                        this.totalRecords = response.data.data.total   
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                                this.$router.push({
                                    name: 'login'
                                });
                        }
                })
    
            },
            onPageChange(page) {
                this.storeIds = []
                document.getElementById('check_all').checked = false;
                document.getElementById('filter_bar').style.display='none';
                this.page = page
                this.getMenuItems();
            },
            onChangeRecordsPerPage() {
              this.storeIds = []
                document.getElementById('check_all').checked = false;
                document.getElementById('filter_bar').style.display='none';
                this.getMenuItems();
                // ds
            },
            updateMenuItemStatus(status,ids){
                const payload = {
                        "ids": ids,
                        "status": status
                };
                console.log('payload',payload)
                const config = {
                            headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
                      };
                const url = `${this.dashBaseUrl}/update-menuitem-status` // this.baseUrl + '/branch/publish'
                this.axios.post(url, payload,config).then((response) => {
                        if(response.status === 200){
                            this.getMenuItems();
                        }
                    })
                    .catch((error) => {
                        this.spinner = false;
                        if (error.response.status === 401) {
                                this.$router.push({
                                    name: 'login'
                                });
                        }
                })
                // 
            }
        },
        mixins: [ReusableFunctionMixins,ReusableDataMixins]
    }
    </script>
    